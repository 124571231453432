<template>
    <div class="hello">
      <h1>Under construction</h1>
    </div>
</template>

<script>
export default {
  name: 'Dashboard'
}
</script>

<style lang="scss" scoped>

</style>
